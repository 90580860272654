import React, { useEffect, useState, useContext } from 'react';
// import PropTypes from "prop-types";
import { WhiteLabelContext } from 'ToolboxUtils/web/context/context';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import Anchor from 'ToolboxComponents/commons/anchors/anchor/anchor';
import Content from 'ToolboxComponents/webapp/layout/content/content';
import PrehomeWrapper from 'ToolboxComponents/webapp/pages/home-view/components/prehome/prehome.style';
// import heroImage1 from 'ToolboxAssets/img/prehome/1.jpg';
// import heroImage2 from 'ToolboxAssets/img/prehome/2.jpg';
// import heroImage3 from 'ToolboxAssets/img/prehome/3.jpg';
import heroImage4 from 'ToolboxAssets/img/prehome/4.jpg';
// import heroImage5 from 'ToolboxAssets/img/prehome/5.jpg';
import SuperWhispererImg from 'ToolboxAssets/img/super-whisperer-400.png';
import getScrollbarWidth from 'ToolboxUtils/web/style/get-scrollbar-width';
import Text from 'ToolboxComponents/commons/texts/text/text';
import ImageResize from 'ToolboxComponents/utils/images/image-resize/image-resize';
import Slider from "ToolboxComponents/commons/sliders/slider/slider/slider";

// const heroImage = [heroImage1,heroImage2,heroImage3,heroImage4,heroImage5][Math.floor(Math.random() * 5)]

const Prehome = props => {
  const [isMobile, setIsMobile] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [swiper, setSwiper] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);

  const [textualContent] = useContext(WhiteLabelContext).usePath(
    'textualContent',
  );
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();

  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  useEffect(() => setScrollbarWidth(getScrollbarWidth()), []);

  const { users, nbSecrets, nbWhisperers } = props;

  return (
    <PrehomeWrapper className={`full-width`} scrollbarWidth={scrollbarWidth} hasStarWhisperers={props.hasStarWhisperers}>
      <section className={`prehome-hero-image`}>
        {whiteLabel.pictures.prehome ?
            whiteLabel.pictures.prehome.length === 1 ?
            <ImageResize
                width={`force-large`}
                src={
                  whiteLabel.pictures.prehome
                      ? whiteLabel.pictures.prehome
                      : heroImage4
                }
            />
            :
          <>
            <Slider
                id="slider"
                getRealCurrentSlideIndex = {index => setCurrentSlide(index)}
                slideTo= {null}
                arrowsColor= "#fff"
                setSwiper= {setSwiper}
                general= {{
                  autoplay: {
                    delay: 5000,
                  },
                }}
                desktop={{
                  arrowsWidth: "80",
                  arrowsHeight: "80",
                  arrowsInside: true,
                  dots: false,
                  arrows: false,
                  centeredSlides: true,
                  spaceBetween: 40,
                  slidesPerView: 1,
                  lazy: true,
                  speed: 4000,
                  slidesPadding: 0,
                }}
                mobile={{
                  arrowsWidth: "80",
                  arrowsHeight: "80",
                  arrowsInside: true,
                  loop: true,
                  arrows: false,
                  dots: false,
                  slidesPerView: 1,
                  lazy: true,
                  speed: 4000
                }}
            >
                {whiteLabel.pictures.prehome.map(pic =>
                    <ImageResize
                        width={`large`}
                        className={`prehome-hero-image`}
                        src={pic}
                    />
                )}
            </Slider>

            <div className="arrow left-arrow" onClick={() => {swiper.slidePrev()}}></div>
          <div className="arrow right-arrow" onClick={() => {swiper.slideNext()}}></div>
            </>
        :
        <ImageResize
            width={`force-large`}
            src={heroImage4}
        />
        }
        <h1 className={`title`}>
          <span className={`secret-count`}>
            <Text
              path={`page.homepage.prehomeNbSecrets`}
              data={{ nbSecrets }}
            />
          </span>
          <span className={`text`}>
            <Text path={`page.homepage.prehomeTitle1`} />
          </span>
          <span className={`text`}>
            <Text path={`page.homepage.prehomeTitle2`} />
          </span>
        </h1>
      </section>
      {props.hasStarWhisperers !== false &&
      <section className={`prehome-banner`}>
        <Content pageCode='prehome'>
          <div className={`title`}>
            <Text path={`page.homepage.prehomeSubTitle1`} />
            {isMobile === true ? <br /> : ' '}
            <Text path={`page.homepage.prehomeSubTitle2`} />
          </div>
          <div className={`users`}>
            {users !== undefined &&
              users.map((user, index) => {
                if (isMobile === false || index < 3) {
                  return (
                    <Anchor
                      code="public"
                      pageParams={`/${user.id}`}
                      className={`user`}
                      key={index}
                    >
                      <div className={`image`}>
                        <ImageResize src={user.photo} />
                      </div>
                      <div className={`name`}>{user.firstName}</div>
                    </Anchor>
                  );
                }
              })}
            <Anchor code="whisperers" className={`whisperer-badge`}>
              <div className={`user-count`}>
                <Text path={`page.homepage.prehomeAnd`} /> {isMobile === true ? nbWhisperers - 3 : nbWhisperers - 6}
              </div>
              <div className={`badge`}>
                <img src={SuperWhispererImg} alt={``} />
              </div>
            </Anchor>
          </div>
        </Content>
      </section>
      }
    </PrehomeWrapper>
  );
};

// Prehome.propTypes = {};

export default Prehome;
